import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { Divider, message } from 'antd'

import { getTicketTypes } from '../../TicketTypes/services'
import { getTicketHints } from '../../TicketHints/services'
import { storeTicket } from '../services'
import { handleError } from '../../../helpers'

export default function NewTicketModal({ visible, onClose, scope, reload = () => {} }) {
	const user = useSelector(state => state.auth.user)
	const [ticketTypes, setTicketTypes] = useState(null)
	const [ticketHints, setTicketHints] = useState(null)
	const [currentStep, setCurrentStep] = useState(1)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors }, watch } = useForm({
		defaultValues: {
			supplier_id: user.id
		}
	})

	useEffect(() => {
		!ticketTypes && visible && getTicketTypes({
			'filter[group_slug]': scope ? scope : '',
			'filter[active]': 1,
			include: 'ticketHints',
			sort: '-created_at,group_slug'
		})
			.then(res => setTicketTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ ticketTypes, scope, visible ])

	const onSubmit = values => {
		setLoading(true)
		storeTicket(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	const handleSelectType = () => {
		if(!watch('ticket_type_id'))
			return message.warning('Debe seleccionar un tipo de solicitud')

		getTicketHints({ 
			'filter[ticket_type_id]': watch('ticket_type_id'),
			sort: 'order'
		})
			.then(res => {
				setTicketHints(res.data.data)
				if(res.data.data.length === 0) setCurrentStep(3)
				else setCurrentStep(2)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Nueva Solicitud de Soporte</ModalHeader>
				<ModalBody>
					{ ticketTypes ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group className='form-group'>
								<Form.Label>Tipo de Solicitud <span className='text-primary'>*</span></Form.Label>
								<Form.Control
									as="select"
									{...register('ticket_type_id', { required:true })}
									disabled={currentStep !== 1}
								>
									<option value="">:: Seleccione una opción ::</option>
									{ ticketTypes.map(ticketType => (
										<option key={ticketType.id} value={ticketType.id}>
											{ticketType.name}
										</option>
									)) }
								</Form.Control>
								{ errors.ticket_type_id && <Form.Text className='text-waring'>Este campo es requerido.</Form.Text> }
							</Form.Group>
							{ currentStep === 1 && (
								<Button color='primary' onClick={handleSelectType}>
									Siguiente
								</Button>
							)}
							{ currentStep === 2 && (
								<React.Fragment>
									{ ticketHints.map(ticketHint => (
										<Alert color="info" key={ticketHint.id} className='p-10 mb-5'>
											<p style={{ whiteSpace:'pre-line' }} className='mb-0'>
												{ticketHint.description}
											</p>
										</Alert>
									)) }
									<div className='mt-15'>
										<Button color='primary' outline onClick={() => setCurrentStep(1)}>
											Volver Atrás
										</Button>
										<Divider type="vertical" />
										<Button color='primary' outline onClick={onClose}>
											Finalizar
										</Button>
										<Divider type="vertical" />
										<Button color='primary' onClick={() => setCurrentStep(3)}>
											Contactar Soporte
										</Button>
									</div>
								</React.Fragment>
							)}
							{ currentStep === 3 && (
								<React.Fragment>
									<Form.Group className='form-group'>
										<Form.Label>Observaciones <span className='text-danger'>*</span></Form.Label>
										<Form.Control 
											as="textarea"
											{...register('main_content', { required:true })}
											style={{ height: '120px' }}
											placeholder='Escriba aquí los detalles de su solicitud'
										/>
										{ errors.main_content && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
									</Form.Group>
									<Button color='primary' outline onClick={() => setCurrentStep(1)}>
										Volver al inicio
									</Button>
									<Divider type="vertical" />
									<Button color="primary" type="submit" disabled={loading}>
										{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
										Enviar Solicitud
									</Button>
								</React.Fragment>
							)}
						</Form>
					) : (
						<Spinner animation='border' size="sm" />
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}