import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Empty, Tooltip, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import DeductionsModal from './DeductionsModal'
import Pagination from '../../../components/Pagination'

import { currency } from '../../../helpers'

export default function InvoicesTable({ invoices, reload, pagination, setPagination }) {
	const [deductionsModal, setDeductionsModal] = useState(null)
	
	const columns = [
		{
			title: 'Consecutivo',
			dataIndex: 'consecutive',
		},
		{
			title: (
				<React.Fragment>
					Valor Neto{" "}
					<Tooltip title="Descontando Retenciones">
						<FontAwesomeIcon icon={faCircleQuestion} />
					</Tooltip>
				</React.Fragment>
			),
			dataIndex: 'total_value',
			render: (t,r) => `$ ${currency(t)} ${r.currency}`
		},
		{
			title: 'Valor Pendiente de Pago',
			dataIndex: 'total_pendding',
			render: (t,r) => `$ ${currency(t)} ${r.currency}`
		},
		{
			title: 'Fecha de admisión',
			dataIndex: 'admitted_at',
			render: t => t ? `${t} (${moment(t).fromNow()})` : <em>Sin registro</em>
		},
		{
			title: 'Concepto',
			dataIndex: 'description',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Retenciones',
			dataIndex: 'certificates',
			render: (t, r) => (
				<React.Fragment>
					{ t.length > 0 ? (
						<p className='text-link mb-0' onClick={() => setDeductionsModal(r)}>
							Ver Retenciones
						</p>
					) : (
						<small><em>Valor no disponible</em></small>
					)}
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={invoices}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!invoices}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ deductionsModal && (
				<DeductionsModal 
					visible
					onClose={() => setDeductionsModal(null)}
					invoice={deductionsModal}
				/>
			)}
		</React.Fragment>
	)
}