import React, { useState } from 'react'
import { Badge, Button } from 'reactstrap'
import { Empty, Table } from 'antd'

import FuturePaymentDetailsModal from './FuturePaymentDetailsModal'

import Pagination from '../../../components/Pagination'
import { currency } from '../../../helpers'

export default function FuturePaymentsTable({ futurePayments, reload, pagination, setPagination }) {
	const [detailsModal, setDetailsModal] = useState(null)

	const columns = [
		{
			title: 'Fecha Estimada del Pago',
			dataIndex: 'schedule_at',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Valor Estimado del Pago',
			dataIndex: 'total_value',
			render: t => `$ ${currency(t)}`
		},
		{
			title: 'Consecutivos',
			dataIndex: 'consecutives',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '100px',
			render: (t,r) => (
				<React.Fragment>
					<Button
						onClick={() => setDetailsModal(r)}
						style={{ padding: '3px 7px', fontSize:'12px' }} 
						color="primary" 
						outline
					>
						Ver detalles
					</Button>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={futurePayments}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!futurePayments}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ detailsModal && (
				<FuturePaymentDetailsModal
					visible
					onClose={() => setDetailsModal(null)}
					futurePayment={detailsModal}
				/>
			)}
		</React.Fragment>
	)
}