import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import InvoicesList from './InvoicesList'

export default function Invoices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/facturas" component={InvoicesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}