import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { Divider, Tooltip } from 'antd'

import ListTopBar from '../../components/ListTopBar'
import FaqsAccordion from '../Faqs/FaqsAccordion'
import TicketsTable from './partials/TicketsTable'
import NewTicketModal from './partials/NewTicketModal'

import { getTickets } from './services'
import { handleError, parsePagination } from '../../helpers'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function TicketsList() {
	const user = useSelector(state => state.auth.user)
	const [tickets, setTickets] = useState(null)
	const [showTicketsModal, setShowTicketsModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!tickets && getTickets({
			...pagination,
			'filter[supplier_id]': user.id,
			include: 'ticketType,ticketNotesCount',
			sort: '-created_at'
		})
			.then(res => {
				setTickets(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ tickets, pagination, user ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Solicitudes de Soporte"
				subtitle="Gestión de soporte a proveedores Spring"
			>
				<Button color='primary' size="sm" className='mt-10' onClick={() => setShowTicketsModal(true)}>
					<FontAwesomeIcon icon={faPlus} className='mr-5' />
					Crear Nueva Solicitud
				</Button>
			</ListTopBar>
			<Divider className='mt-15 mb-10' />
			<Row>
				<Col md="4" style={{ borderRight: '1px solid #e8e8e8' }} className='mb-15'>
					<h6 className='section-title'>PREGUNTAS FRECUENTES</h6>
					<FaqsAccordion />
					<h6 className='section-title mb-10'>CORREO PARA NOTIFICACIONES</h6>
					<Form.Control value={user.notification_email ? user.notification_email : 'Sin registro'} disabled />
					<Link to="/mi_cuenta" className='mt-5 block'>Modificar</Link>
				</Col>
				<Col md="8">
					<div className='float-right'>
						<Tooltip title="Refrescar">
							<FontAwesomeIcon 
								icon={faRefresh} 
								className='text-link mr-5'
								onClick={() => setTickets(null)}
							/>
						</Tooltip>
					</div>
					<h6 className='section-title'>HISTORIAL DE SOLICITUDES</h6>
					<TicketsTable
						tickets={tickets}
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setTickets(null)}
					/>
				</Col>
			</Row>
			{ showTicketsModal && (
				<NewTicketModal
					visible
					onClose={() => setShowTicketsModal(false)}
					reload={() => setTickets(null)}
				/>
			)}
		</React.Fragment>
	)
}