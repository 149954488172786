import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Table } from 'antd'

import { currency } from '../../../helpers'
import DeductionsModal from '../../Invoices/partials/DeductionsModal'

export default function PaymentDetailsModal({ visible, onClose, payment }) {
	const [deductionsModal, setDeductionsModal] = useState(null)

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size='md'>
				<ModalHeader toggle={onClose}>Detalles del Pago {payment.sku}</ModalHeader>
				<ModalBody>
					<p className='mb-0'><strong>Fecha del Pago: </strong>{payment.paid_at}</p>
					<p className='mb-10'>
						<strong>Tipo de transacción: </strong>
						{payment.payment_type ? `${payment.payment_type.name}` : 'Sin especificar'}
					</p>
					<Table 
						dataSource={payment.payment_details}
						rowKey={r => r.id}
						size="small"
						bordered
						columns={[
							{
								title: 'Factura',
								dataIndex: 'invoice',
								render: t => t ? t.consecutive : <em>Anticipo pendiente de asociar a FC</em>
							},
							{
								title: 'Valor del Pago',
								dataIndex: 'value',
								render: (t,r) => `$ ${currency(t*-1)} ${r.currency}`
							},
							{
								title: 'Retenciones',
								dataIndex: 'invoice',
								render: (t, r) => (
									<React.Fragment>
										{ t ? (
											<React.Fragment>
												{ t.certificates.length > 0 ? (
													<p className='text-link mb-0' onClick={() => setDeductionsModal(t)}>
														Ver Retenciones
													</p>
												) : (
													<small><em>Valor no disponible</em></small>
												)}
											</React.Fragment>
										) : (
											<em>Sin factura asociada</em>
										)}
									</React.Fragment>
								)
							}
						]}
					/>
				</ModalBody>
			</Modal>
			{ deductionsModal && (
				<DeductionsModal
					visible
					onClose={() => setDeductionsModal(null)}
					invoice={deductionsModal}
				/>
			)}
		</React.Fragment>
	)
}