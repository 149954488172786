import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { Divider } from 'antd'

import ListTopBar from '../../components/ListTopBar'
import AccountDetails from './partials/AccountDetails'
import NewTicketModal from '../Tickets/partials/NewTicketModal'

export default function AccountProfile() {
	const [showTicketsModal, setShowTicketsModal] = useState(false)

	return (
		<React.Fragment>
			<ListTopBar
				title="Mi Cuenta de Proveedor"
				subtitle="Consulte los datos relacionados a su cuenta"
			>
				<Button color='warning' size="sm" className='mt-10' onClick={() => setShowTicketsModal(true)}>
					<FontAwesomeIcon icon={faLifeRing} className='mr-5' />
					Solicitar Actualización de Datos
				</Button>
			</ListTopBar>
			<Divider className='mt-15 mb-10' />
			<Row>
				<Col lg="5" md="6">
					<AccountDetails />
				</Col>
			</Row>
			{ showTicketsModal && (
				<NewTicketModal
					visible
					onClose={() => setShowTicketsModal(false)}
					scope="myProfile"
				/>
			)}
		</React.Fragment>
	)
}