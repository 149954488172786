import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AuthLayout from '../../layouts/AuthLayout'
import Login from './Login'
import Logout from './Logout'
import Impersonation from './Impersonation'

export default function Authentication() {
	const token = useSelector(state => state.auth.token)

	return (
		<AuthLayout>			
			<Switch>			
				<Redirect exact path="/auth" to="/auth/login" />
				<Route exact path="/auth/logout" component={Logout} />
				<Route exact path="/auth/impersonation/:supplierId/:adminId" component={Impersonation} />

				{ token && <Redirect to="/" /> }
				<Route exact path="/auth/login" component={Login} />

				<Redirect to="/404" />
			</Switch>
		</AuthLayout>
	)
}