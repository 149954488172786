import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { Divider } from 'antd'

import ListTopBar from '../../components/ListTopBar'
import CertificateForm from './partials/CertificateForm'
import NewTicketModal from '../Tickets/partials/NewTicketModal'
import AdminAlertMessage from '../../components/AdminAlertMessage'

export default function CertificatesList() {
	const [showTicketsModal, setShowTicketsModal] = useState(false)

	return (
		<React.Fragment>
			<ListTopBar
				title="Solicitar Certificados"
				subtitle="Certificaciones de retenciones aplicadas"
			>
				<Button color='warning' size="sm" className='mt-10' onClick={() => setShowTicketsModal(true)}>
					<FontAwesomeIcon icon={faLifeRing} className='mr-5' />
					No encuentro el certificado que busco
				</Button>
			</ListTopBar>
			<Divider className='mt-15 mb-10' />
			<AdminAlertMessage messageKey="certificates_alert" />
			<p className='hint-text mb-10'>
				Diligencie el siguiente formulario para descargar sus certificados de retenciones aplicadas.
			</p>
			<Row>
				<Col lg="4" md="6">
					<CertificateForm />
				</Col>
			</Row>
			{ showTicketsModal && (
				<NewTicketModal
					visible
					onClose={() => setShowTicketsModal(false)}
					scope="certificates"
				/>
			)}
		</React.Fragment>
	)
}