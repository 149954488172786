import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Descriptions, Empty } from 'antd'

import DeductionsModal from '../../Invoices/partials/DeductionsModal'

import { getInvoices } from '../../Invoices/services'
import { currency, handleError } from '../../../helpers'

const { Item } = Descriptions

export default function FuturePaymentDetailsModal({ visible, onClose, futurePayment }) {
    const [invoices, setInvoices] = useState(null)
    const [deductionsModal, setDeductionsModal] = useState(null)

    useEffect(() => {
        !invoices && getInvoices({
            'filter[supplier_id]': futurePayment.supplier_id,
            'filter[consecutive]': futurePayment.consecutives,
            include: 'certificates',
            per_page: 100
        })  
            .then(res => setInvoices(res.data.data))
            .catch(error => handleError(error))
    }, [ invoices, futurePayment ])

    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={onClose}>
                <ModalHeader toggle={onClose}>Detalle de Pago Programado #{futurePayment.id}</ModalHeader>
                <ModalBody>
                    { invoices && (
                        <React.Fragment>
                            { invoices.length > 0 ? (
                                <Descriptions bordered size="small" column={1}>
                                    { invoices.map(invoice => (
                                        <Item
                                            key={invoice.id}
                                            label={invoice.consecutive}
                                        >
                                            ${currency(invoice.total_pendding)} {invoice.currency}{" "}
                                            { invoice.certificates.length > 0 && (
                                                <small className='text-link mb-0' onClick={() => setDeductionsModal(invoice)}>
                                                    Ver Retenciones
                                                </small>
                                            )}
                                        </Item>
                                    )) }
                                </Descriptions>
                            ) : (
                                <Empty description="No se encontro detalles asociados" />
                            )}
                        </React.Fragment>
                    )}
                </ModalBody>
            </Modal>
			{ deductionsModal && (
				<DeductionsModal
					visible
					onClose={() => setDeductionsModal(null)}
					invoice={deductionsModal}
				/>
			)}
        </React.Fragment>
    )
}