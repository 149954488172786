import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Spinner } from 'react-bootstrap'

import { userLogin } from './actions'
import { loginImpersonation } from './services'
import { handleError } from '../../helpers'

export default function Impersonation(props) {
    const { supplierId, adminId } = props.match.params
	const dispatch = useDispatch()

	useEffect(() => {
		loginImpersonation({ supplierId, adminId })
            .then(res => {
                dispatch(userLogin(res.data))
                window.location.href = '/'
            })
            .catch(error => handleError(error))
	}, [ supplierId, adminId, dispatch ])

	return (
		<React.Fragment>
			<div className="text-center">
				<Spinner animation='border' />
			</div>
		</React.Fragment>
	)
}