import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import AccountProfile from './AccountProfile'

export default function Profile() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/mi_cuenta" component={AccountProfile} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}