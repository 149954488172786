import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Empty, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import PaymentDetailsModal from './PaymentDetailsModal'

import Pagination from '../../../components/Pagination'
import { currency } from '../../../helpers'

export default function PaymentsTable({ payments, reload, pagination, setPagination }) {
	const [detailsModal, setDetailsModal] = useState(null)
	
	const columns = [
		{
			title: 'Fecha del Pago',
			width: '210px',
			dataIndex: 'paid_at',
			render: t => t ? `${t} (${moment(t).fromNow()})` : <em>Sin registro</em>
		},
		{
			title: 'Valor Total del Pago',
			width: '160px',
			dataIndex: 'total_value',
			render: t => `$ ${currency(t*-1)}`
		},
		{
			title: 'Consecutivos Asociados',
			dataIndex: 'payment_details',
			render: t => `${ t.map(t => t.invoice ? `${t.invoice.consecutive}, ` :  'ANTICIPO') }`
		},
		{
			title: 'Acciones',
			width: '100px',
			render: (t,r) => (
				<React.Fragment>
					<Button 
						onClick={() => setDetailsModal(r)}
						style={{ padding: '3px 7px', fontSize:'12px' }} 
						color="primary" 
						outline
					>
						Ver detalles
					</Button>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Consecutivo interno: </strong>
				{r.sku}
			</p>
			<p className='mb-0'>
				<strong>Tipo de transacción: </strong>
				{r.payment_type ? `${r.payment_type.name}` : 'Sin especificar'}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={payments}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!payments}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ detailsModal && (
				<PaymentDetailsModal 
					visible
					onClose={() => setDetailsModal(null)}
					payment={detailsModal}
				/>
			)}
		</React.Fragment>
	)
}