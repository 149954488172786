import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'
import { Button, Col, Row } from 'reactstrap'
import { Empty } from 'antd'
import moment from 'moment'

import { getCertificates } from './services'
import { currency, handleError } from '../../helpers'

export default function CertificatePreview() {
	const user = useSelector(state => state.auth.user)
	const { type, year, month } = useParams()
	const [certificates, setCertificates] = useState(null)

	useEffect(() => {
	  !certificates && getCertificates({
			'filter[supplier_id]': user.id,
			'filter[type]': type,
			'filter[year]': year,
			'filter[month]': month,
			include: 'supplier,invoice',
			per_page: 1000,
		})
			.then(res => {
				setCertificates(res.data.data)
			})
			.catch(error => handleError(error))
	}, [ certificates, user, type, year, month ])
	

	const typeName = () => {
		switch (parseInt(type)) {
			case 1: return 'CERTIFICADO DE RETENCION DE IVA'
			case 2: return 'CERTIFICADO DE RETENCION DE ICA'
			case 3: return 'CERTIFICADO DE RETENCION EN LA FUENTE'
		
			default: return 'SIN DEFINIR'
		}
	}

	if(!certificates) return <Spinner animation='border' />
	
	const getTotal = () => (
		certificates.reduce((total, certificate) => {
			return total + certificate.value;
		}, 0)
	)

	const getBaseTotal = () => (
		certificates.reduce((total, certificate) => {
			return total + certificate.base;
		}, 0)
	)

	const getLocations = () => {
		var locations = []
	 
		for (var i = 0; i < certificates.length; i++){
			if(certificates[i].location && !locations.includes(certificates[i].location)) 
				locations.push(certificates[i].location)
		}
	 
		return locations.join(", ")
	}

	return (
		<React.Fragment>
			<div className='mb-20'>
				<div className='float-right no-print'>
					<Button color='primary' outline onClick={() => window.print()}>
						Imprimir
					</Button>
				</div>
			</div>
			<div className='pt-15 px-10'>
				<div className='text-center mb-20'>
					<p className='mb-1 fs-16'><strong>Industrias Spring S.A.S</strong></p>
					<p className='mb-1'>Aut. Mill.Ent.2.2 km Vía Cota</p>
					<p className='mb-30'>NIT: 860000794-1</p>
					<h6 className='mb-30 fs-16'>{typeName()}</h6>
				</div>
			</div>
			<Row className='px-30 mb-20'>
				<Col>
					<p className='mb-0'>Fecha de expedición</p>
					<p>{moment().format('DD.MM.YYYY')}</p>
				</Col>
				<Col>
					<p className='mb-0'>Año Gravable</p>
					<p>{year}</p>
				</Col>
				<Col>
					<p className='mb-0'>Periodo</p>
					<p>{month}</p>
				</Col>
			</Row>
			{ certificates.length > 0 ? (
				<div className='px-30'>
					<p className='mb-0'>Certifica que practicó</p>
					<p className='mb-0'>{typeName().replace('CERTIFICADO DE ', '')} a: <strong>{certificates[0].supplier.name}</strong></p>
					<p>NIT/C.C:  <strong>{certificates[0].supplier.document}-{certificates[0].supplier.verification_digit}</strong></p>
					<p className='mb-30'>Lugar donde se practicó la retención: {getLocations()}</p>
					<table className='table mb-30'>
						<thead>
							<tr>
								<th>Concepto de la operación</th>
								{ type === '2' && <th>Lugar donde se practicó</th> }
								<th>Valor base de la retención</th>
								<th>%</th>
								<th>Retención aplicada</th>
							</tr>
						</thead>
						<tbody>
							{ certificates.map(certificate => (
								<tr key={certificate.id}>
									<td>Retención aplicada a factura {certificate.invoice.consecutive}</td>
									{ type === '2' && <td>{certificate.location}</td> }
									<td>$ {currency(certificate.base)} COP</td>
									<td>{certificate.rate.toFixed(2)} %</td>
									<td>$ {currency(certificate.value)} COP</td>
								</tr>
							))}
							<tr className='bold'>
								<td className='text-right pr-20'>TOTAL:</td>
								{ type === '2' && <td></td> }
								<td>$ {currency(getBaseTotal())} COP</td>
								<td></td>
								<td>$ {currency(getTotal())} COP</td>
							</tr>
						</tbody>
					</table>
					<div className='text-center mt-30 pt-30'>
						<p className='small mb-0'><em>El presente certificado se expide en concordancia con las disposiciones legales contenidas en el artículo 381 del E.T.</em></p>
						<p className='small'><em>*** Forma Continua Impresa por Computador No necesita Firma Autografa ***</em></p>
					</div>
				</div>
			) : (
				<Empty description="No se encontro certificados para el periodo señalado." />
			)}
		</React.Fragment>
	)
}