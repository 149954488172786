import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Descriptions } from 'antd'

import { currency } from '../../../helpers'

const { Item } = Descriptions

export default function DeductionsModal({ visible, onClose, invoice }) {
	
	const getTotal = () => (
		invoice.certificates.reduce((total, deduction) => {
			return total + deduction.value;
		}, 0)
	)

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Retenciones Aplicadas</ModalHeader>
				<ModalBody>
					<Descriptions bordered size="small" column={1}>
						{ invoice.certificates.map(deduction => (
							<Item
								key={deduction.id}
								label={deduction.type}
							>
								${currency(deduction.value)} COP{" "}
								<small>(Base: ${currency(deduction.base)} COP - {deduction.rate.toFixed(2)}%)</small>
							</Item>
						)) }
					</Descriptions>
					<p className='mt-10 mb-0'>
						<strong>Total Retenido: </strong>${currency(getTotal())} COP
					</p>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}