import React from 'react'
import styled from 'styled-components'
import { Breadcrumb, Layout } from 'antd'
import { useSelector } from 'react-redux'

import Logo from '../assets/images/logo_spring.png'
import MainMenu from './partials/MainMenu'

const { Header, Content, Footer, Sider } = Layout

export default function MainLayout({ children }) {
	const user = useSelector(state => state.auth.user)

	const getPageTitle = () => {
		let path = window.location.href.split('#')[1]
		if(path.includes('/facturas')) return 'FACTURAS RADICADAS'
		if(path.includes('/pagos_programados')) return 'PAGOS PROGRAMADOS'
		if(path.includes('/pagos')) return 'PAGOS RECIBIDOS'
		if(path.includes('/certificados')) return 'CERTIFICADOS DE RETENCIONES'
		if(path.includes('/solicitudes')) return 'SOPORTE A PROVEEDORES'
		if(path.includes('/mi_cuenta')) return 'MI CUENTA PROVEEDOR SPRING'

		return 'PÁGINA NO ENCONTRADA'
	}

	return (
		<Container>
			<Layout className="layout">
				<Header
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#222',
					}}
				>
					<LogoContainer>
						<img src={Logo} alt="Logo Spring" width="100px" />
					</LogoContainer>
				</Header>
				<Layout style={{ padding: '20px 10px', minHeight: '85vh' }}>
					<Sider 
						width={230} 
						style={{ backgroundColor: '#f5f5f5' }}
						breakpoint="lg"
						collapsedWidth="0"
						className='no-print'
					>
						<div className='bg-light p-20 mb-10'>
							<p className='small mb-5'>Bienvenido</p>
							<p className='bold mb-0'>{user.name}</p>
							<p className='mb-0'>NIT {user.document}</p>
							<p className='mb-0 hint-text'>ID Plataforma {user.id}</p>
						</div>
						<MainMenu />
					</Sider>
					<Content style={{ padding: '0 5px 0 10px' }}>
						<Breadcrumb 
							style={{margin: '0px 0 10px',fontWeight:'bold'}}
							className='no-print'
							items={[
								{ title: 'CEN FINANCIERO SPRING' },
								{ title: getPageTitle() }
							]}
						/>
						<div
							className="site-layout-content"
							style={{background: '#fff', padding: '10px 15px'}}
						>
							{ children }
						</div>
					</Content>
				</Layout>
				<Footer style={{ textAlign: 'center', fontSize: '13px' }}>
					Desarrollado por{" "}
					<a href="//smart4.com.co" target="_blank" rel="noreferrer">
						Smart4 Solutions
					</a>
					{" | "}
					Versión {process.env.REACT_APP_VERSION}
				</Footer>
			</Layout>
		</Container>
	)
}

const Container = styled.div`
	& .ant-menu-title-content {
		color: #e42537;
		font-weight: 500;
	}
	& .ant-menu-item {
		margin-bottom: 10px;
		background-color: #fff;
	}
	& .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
		background-color: #e8e8e8;
	}
	& .ant-menu-light.ant-menu-root.ant-menu-inline {
		border: none;
	}
	& .ant-layout-header {
		height: 31px;
    	margin-bottom: 15px;
	}
`

const LogoContainer = styled.div`
	background-color: rgba(255, 255, 255, 1);
	display: inline-block;
	margin: 0 auto;
	width: 155px;
	text-align: center;
	border-radius: 25px;
	height: 45px;
	position: relative;
	left: -15px;
	top: 13px;
	& img {
		margin-top: -25px;
	}
`