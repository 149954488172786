import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'
import TicketNotesModal from '../../TicketNotes/partials/TicketNotesModal'

export default function TicketsTable({ tickets, reload, pagination, setPagination }) {
	const [notesModal, setNotesModal] = useState(null)

	const columns = [
		{
			title: 'ID',
			width: '50px',
			dataIndex: 'id',
			render: t => `00${t}`
		},
		{
			title: 'Fecha Solicitud',
			width: '140px',
			dataIndex: 'created_at',
			render: t => <span style={{ whiteSpace:'pre-line' }}>{`${t} \n(${moment(t).fromNow()})`}</span>
		},
		{
			title: 'Tipo de Solicitud',
			dataIndex: 'ticket_type',
			render: t => t.name
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Comentarios" className='text-link' onClick={() => setNotesModal(r)}>
						<FontAwesomeIcon icon={faComments} />{" "}
						({r.ticket_notes_count})
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'><strong>Mensaje inicial: </strong>{r.ticket_type.name}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={tickets}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!tickets}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ notesModal && (
				<TicketNotesModal 
					visible
					onClose={() => setNotesModal(null)}
					ticket={notesModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}