import springCenfApi from '../../services/springCenfApi'

export const login = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/auth/login/suppliers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const loginImpersonation = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/auth/impersonation/suppliers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const logout = () => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/auth/logout')
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)