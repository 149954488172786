import React from "react"
import { useSelector } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Tickets from "./containers/Tickets"
import Profile from "./containers/Profile"
import Payments from "./containers/Payments"
import Invoices from "./containers/Invoices"
import Certificates from "./containers/Certificates"
import FuturePayments from "./containers/FuturePayments"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/solicitudes" />

				<Route path="/auth" component={Authentication} />
				
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>				
					<Route path="/facturas" component={Invoices} />
					<Route path="/pagos" component={Payments} />
					<Route path="/pagos_programados" component={FuturePayments} />
					<Route path="/certificados" component={Certificates} />
					<Route path="/solicitudes" component={Tickets} />
					<Route path="/mi_cuenta" component={Profile} />
					
					<Route exact path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}