import React, { useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import { updateSupplier } from '../services'
import { handleError } from '../../../helpers'
import { userUpdate } from '../../Authentication/actions'

export default function AccountDetails() {
	const user = useSelector(state => state.auth.user)
	const dispatch = useDispatch()
	const [notificationEmail, setNotificationEmail] = useState(user.notification_email)
	const [newPassword, setNewPassword] = useState('')
	const [loading, setLoading] = useState(false)

	const onSubmit = () => {
		if(!notificationEmail) 
			return message.warning('Debe ingresar un correo de notificación válido')

		setLoading(true)

		updateSupplier(user.id, { 
			notification_email: notificationEmail 
		})
			.then(res => {
				message.success(res.data.message)
				dispatch(userUpdate(res.data.data))
				setLoading(false)
			})
			.catch(error => handleError(error, setLoading))
	}

	const onChangePassword = () => {
		if(!newPassword) 
			return message.warning('Debe ingresar un correo de notificación válido')

		setLoading(true)

		updateSupplier(user.id, { 
			password: newPassword,
			password_confirmation: newPassword,
		})
			.then(res => {
				message.success(res.data.message)
				setNewPassword('')
				setLoading(false)
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Razón Social</Form.Label>
				<Form.Control value={user.name} disabled />
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>NIT</Form.Label>
				<Form.Control value={user.document} disabled />
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Contacto Principal</Form.Label>
				<Form.Control value={user.contact_name} disabled />
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Celular del Contacto</Form.Label>
				<Form.Control value={user.contact_mobile} disabled />
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Correo del Contacto</Form.Label>
				<Form.Control value={user.contact_email} disabled />
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Correo para Notificaciones</Form.Label>
				<InputGroup>
					<Form.Control 
						placeholder='Sin especificar'
						value={notificationEmail}
						onChange={e => setNotificationEmail(e.target.value)}
					/>
					<Button disabled={loading} onClick={onSubmit}>
						{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
						Actualizar
					</Button>
				</InputGroup>
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Cambiar Contraseña</Form.Label>
				<InputGroup>
					<Form.Control 
						placeholder='Escriba aquí la nueva contraseña'
						value={newPassword}
						onChange={e => setNewPassword(e.target.value)}
						type="password"
					/>
					<Button disabled={loading} onClick={onChangePassword}>
						{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
						Cambiar
					</Button>
				</InputGroup>
			</Form.Group>
		</React.Fragment>
	)
}