import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { storeTicketNote } from '../services'
import { handleError } from '../../../helpers'

export default function NewTicketNoteModal({ visible, onClose, ticket, reload }) {
	const user = useSelector(state => state.auth.user)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			ticket_id: ticket.id,
			supplier_id: user.id
		}
	})

	const onSubmit = values => {
		setLoading(true)
		storeTicketNote(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Comentario Nuevo</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='form-group'>
							<Form.Label>Comentario <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('content', { required:true })}
								placeholder='Escriba aquí...'
								style={{ height: '150px' }}
							/>
							{ errors.content && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button
							color="primary"
							type="submit"
							disabled={loading}
						>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar Comentario
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}