import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Collapse, Empty } from 'antd'

import { getFaqs } from './services'
import { handleError } from '../../helpers'

export default function FaqsAccordion() {
	const [faqs, setFaqs] = useState(null)

	useEffect(() => {
		!faqs && getFaqs({
			'filter[active]':1
		})
			.then(res => setFaqs(res.data.data))
			.catch(error => handleError(error))
	}, [ faqs ])

	if(!faqs) return <Spinner animation='border' size="sm" />

	const items = faqs.map(faq => ({
		key: faq.id,
		label: faq.question,
		children: <p style={{ whiteSpace: 'pre-line' }}>{faq.answer}</p>
	}))

	return (
		<React.Fragment>
			{ faqs.length > 0 ? (
				<Collapse 
					accordion
					items={items}
					size="small"
					className='mb-20'
				/>
			) : (
				<Empty description="No se han creado preguntas frecuentes" />
			)}
		</React.Fragment>
	)
}