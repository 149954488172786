import axios from "axios"

import { store } from "../store"

export const getUri = () => {
   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'spring_cenf_api.test' : 'apicenf.spring.com.co'

   return `https://${domain}`
}

export const getDomain = () => {
   var uri = getUri()
   
   return `${uri}/api`
}

export const getAdminUri = () => {
   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'http://localhost:3011' : 'https://admin.cenf.spring.com.co'

   return domain
}

/*
 * Instancia con el servicio principal del backend
 */
const springCenfApi = () => {
   const state = store.getState();
   const authToken = state.auth.token;

   return axios.create({
      baseURL: getDomain(),
      timeout: 60000,
      headers: {
         Authorization: `Bearer ${authToken}`,
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default springCenfApi;
