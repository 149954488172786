import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek, faDownload, faFileCircleCheck, faIdCard, faLifeRing, faMoneyBillTransfer, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { Menu } from 'antd'

import { logout } from '../../containers/Authentication/services'
import { userLogout } from '../../containers/Authentication/actions'
import { handleError } from '../../helpers'

export default function MainMenu() {
	const dispatch = useDispatch()
	const history = useHistory()

	const onLogout = () => {
		logout()
			.then(() => dispatch(userLogout()))
			.catch(error => handleError(error))
	}

	const menuItems = [
		{ 
			key: 'facturas', 
			label: 'Facturas Radicadas',
			icon: <FontAwesomeIcon icon={faFileCircleCheck} />,
			onClick: () => history.push('/facturas')
		},
		{ 
			key: 'detalle_de_pagos', 
			label: 'Pagos Recibidos',
			icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
			onClick: () => history.push('/pagos')
		},
		{ 
			key: 'pagos_programados', 
			label: 'Pagos Programados',
			icon: <FontAwesomeIcon icon={faCalendarWeek} />,
			onClick: () => history.push('/pagos_programados')
		},
		{ 
			key: 'certificados', 
			label: 'Solicitar Certificados',
			icon: <FontAwesomeIcon icon={faDownload} />,
			onClick: () => history.push('/certificados')
		},
		{ 
			key: 'solicitudes', 
			label: 'Soporte a Proveedores',
			icon: <FontAwesomeIcon icon={faLifeRing} />,
			onClick: () => history.push('/solicitudes')
		},
		{ 
			key: 'mi_cuenta', 
			label: 'Mi Cuenta Proveedor',
			icon: <FontAwesomeIcon icon={faIdCard} />,
			onClick: () => history.push('/mi_cuenta')
		},
		{ 
			key: 'logout',
			label: <span className='text-dark'>Cerrar Sesión</span>, 
			icon: <FontAwesomeIcon icon={faSignOut} />,
			onClick: () => onLogout()
		},
	]

	return (
		<React.Fragment>
			<Menu
				mode="inline"
				style={{ backgroundColor: '#f5f5f5' }}
				items={menuItems}
			/>
		</React.Fragment>
	)
}