import React, { useState, useEffect } from 'react'
import { Alert } from 'reactstrap'

import { getSettings } from '../containers/Settings/services'
import { handleError } from '../helpers'

export default function AdminAlertMessage({ messageKey }) {
	const [alerts, setAlerts] = useState(null)

    useEffect(() => {
        !alerts && getSettings({ 'filter[key]': messageKey })
            .then(res => setAlerts(res.data.data))
            .catch(error => handleError(error))
    }, [ alerts, messageKey ])

	return (
		<React.Fragment>
            { alerts && alerts.length > 0 && (
                <React.Fragment>
                    { alerts.map(alert => (
                        <div key={alert.id}>
                            { alert.value && (
                                <Alert color="warning" className='p-5 mb-5'>{alert.value}</Alert>
                            )}
                        </div>
                    )) }
                </React.Fragment>
            )}
        </React.Fragment>
	)
}