import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Empty } from 'antd'

import NewTicketNoteModal from './NewTicketNoteModal'

import { getTicketNotes } from '../services'
import { handleError } from '../../../helpers'
import { Spinner } from 'react-bootstrap'

export default function TicketNotesModal({ visible, onClose, ticket, reload }) {
	const [ticketNotes, setTicketNotes] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!ticketNotes && visible && getTicketNotes({
			'filter[ticket_id]': ticket.id,
			include: 'user,supplier',
			sort: 'created_at'
		})
			.then(res => setTicketNotes(res.data.data))
			.catch(error => handleError(error))
	}, [ ticketNotes, visible, ticket ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Comentarios de Solicitud #0{ticket.id}</ModalHeader>
				<ModalBody>
					{ ticketNotes ? (
						<React.Fragment>
							<Alert color="light" className='p-7 mb-10'>
								<strong>Mensaje inicial: </strong> {ticket.main_content}
							</Alert>
							{ ticketNotes.length > 0 ? (
								<React.Fragment>
									{ ticketNotes.map(ticketNote => (
										<Alert 
											key={ticketNote.id} 
											color={ticketNote.supplier_id ? 'success' : 'info'}
											className={`mb-10 p-7 ${ ticketNote.supplier_id ? 'ml-30' : 'mr-30' }`}
										>
											<p className='mb-0 small'><em>Fecha de envió: {ticketNote.created_at}</em></p>
											<p className='mb-5 mt-5' style={{ whiteSpace:'pre-line' }}>
												{ ticketNote.content }
											</p>
											<p className='mb-0 small'>
												<em>Eviado por: {ticketNote.supplier ? ticketNote.supplier.name : ticketNote.user.name}</em>
											</p>											
										</Alert>
									)) }
								</React.Fragment>
							) : (
								<Empty description="Aún no se han agregado comentarios" />
							)}
						</React.Fragment>
					) : (
						<Spinner animation='border' size="sm" className='block' />
					)}
					<Button
						color='primary'
						className='mt-10'
						onClick={() => setShowNewModal(true)}
						disabled={ticket.status.key !== 'active'}
					>
						Agregar Comentario
					</Button>
					{ ticket.status.key !== 'active' && (
						<p className='text-muted mb-0 mt-2 small'>
							<em>Solo puede enviar comentarios a solicitudes activas</em>
						</p>
					)}
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewTicketNoteModal 
					visible
					onClose={() => setShowNewModal(false)}
					ticket={ticket}
					reload={() => {
						reload()
						setTicketNotes(null)
					}}
				/>
			)}
		</React.Fragment>
	)
}