import springCenfApi from '../../services/springCenfApi'

export const getPayments = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/payments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPayment = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/payments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)