import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'

import ListTopBar from '../../components/ListTopBar'

import NewTicketModal from '../Tickets/partials/NewTicketModal'
import FuturePaymentsTable from './partials/FuturePaymentsTable'

import { storeDownloadToken } from '../DownloadTokens/services'
import { getUri } from '../../services/springCenfApi'
import { getFuturePayments } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function FuturePaymentsList() {
	const user = useSelector(state => state.auth.user)
	const [futurePayments, setFuturePayments] = useState(null)
	const [filterType, setFilterType] = useState('schedule_at')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showTicketsModal, setShowTicketsModal] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!futurePayments && getFuturePayments({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[supplier_id]': user.id,
			'filter[active]': 1,
			include: '',
			sort: 'schedule_at'
		})
			.then(res => {
				setFuturePayments(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ futurePayments, pagination, filterType, filterValue, user ])

	const handleExport = () => {
		setLoading(true)
		let token = new Date().getTime()
		storeDownloadToken({ type: `Descarga de Pagos Programados`, token, supplier_id: user.id })
			.then(() => {
				message.info('Generando reporte en nueva pestaña')
				window.open(`${getUri()}/reports/export/future_payments/${user.id}/${token}`)
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<ListTopBar 
				title="Pagos Programados"
				subtitle="Listado de pagos programados a futuro"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setFuturePayments(null)}
				options={[
					{ label: 'Buscar por fecha (YYYY-MM-DD)', value: 'schedule_at' },
					{ label: 'Buscar por consecutivo', value: 'consecutives' },
				]}
				onExport={handleExport}
				disabledExport={loading}
			/>
			<div className='inline-block full-width'>
				<Alert className='p-5 mt-10 mb-10' color='info'>Estos pagos son estimados y la fecha y detalle de realización puede variar en el tiempo.</Alert>
			</div>
			<FuturePaymentsTable 
				futurePayments={futurePayments}
				reload={() => setFuturePayments(null)}
				pagination={pagination}
				setPagination={setPagination}
			/>			
			<Button color='warning' size="sm" onClick={() => setShowTicketsModal(true)}>
				<FontAwesomeIcon icon={faLifeRing} className='mr-5' />
			   No encuentro el pago que busca
			</Button>
			{ showTicketsModal && (
				<NewTicketModal
					visible
					onClose={() => setShowTicketsModal(false)}
					scope="futurePayments"
				/>
			)}
		</React.Fragment>
	)
}