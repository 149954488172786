import React from 'react'
import { Button, CardTitle, CardSubtitle } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'

export default function ListTopBar({ 
	filterType, 
	setFilterType, 
	filterValue, 
	setFilterValue, 
	filterScope,
	setFilterScope,
	reload,
	title,
	subtitle,
	options,
	scopes,
	ctaText,
	handleCtaClick,
	className,
	prefix,
	onExport,
	secundaryCtaText,
	handleSecundaryCtaClick,
	children,
	disabledExport = false,
}) {
	return (
		<React.Fragment>
			<div className={`list-top-bar pt-0 ${className}`}>
				<div className="float-right mb-10">
					{ options && (
						<React.Fragment>
							<InputGroup>
								{ prefix && (
									<InputGroup.Text>
										{prefix}
									</InputGroup.Text>
								)}
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									{options.map((option,i) => 
										<option key={i} value={option.value}>{option.label}</option>
									)}
								</Form.Control>
								<Form.Control 
									placeholder="Escriba aquí" 
									value={filterValue}
									onChange={e => setFilterValue(e.target.value)}
								/>
								<Button color={ctaText ? 'dark' : 'primary'} onClick={reload}>Buscar</Button>
								{ secundaryCtaText && (
									<Button className="ml-5" color="primary" onClick={handleSecundaryCtaClick}>{secundaryCtaText}</Button>
								)}
								{ ctaText && (
									<Button className="ml-5" color="primary" onClick={handleCtaClick}>{ctaText}</Button>
								)}
								{ onExport && (
									<Button className="ml-5" color="primary" onClick={onExport} disabled={disabledExport}>Exportar</Button>
								)}
							</InputGroup>
							{ scopes && (
								<InputGroup className='mt-5' style={{ maxWidth: '400px', float: 'right' }}>
									<Form.Control 
										disabled 
										value="Estado" 
										style={{ maxWidth:'70px' }} 
										className="bg-light"
									/>
									<Form.Control
										as="select"
										value={filterScope}
										onChange={e => {
											setFilterScope(e.target.value)
											reload()
										}}
									>
										<option value="">:: Ver todos los estados ::</option>
										{ scopes.map(scope => (
											<option key={scope.value} value={scope.value}>{scope.label}</option>
										)) }
									</Form.Control>
								</InputGroup>
							)}
						</React.Fragment>
					)}
					{ children }
				</div>
				<div className='hide-md'>
					{ title && <CardTitle className='mb-0'>{title}</CardTitle> }
					{ subtitle && <CardSubtitle>{subtitle}</CardSubtitle> }
				</div>
			</div>
		</React.Fragment>
	)
}